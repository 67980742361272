import { Component, OnInit, ɵConsole } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Conductor } from 'src/app/models/conductor.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-conductor',
  templateUrl: './conductor.component.html',
  styleUrls: ['./conductor.component.css']
})
export class ConductorComponent implements OnInit {

  respuesta: any;
  rutSearch: string ;
  conductor: Conductor;
  cod: Object;
  forma: FormGroup = new FormGroup({
    // 'rut': new FormControl('', Validators.required ),
    'Conductor': new FormControl('', Validators.required ),
    'Usuario': new FormControl('', Validators.required ),
    'Password': new FormControl('', Validators.required ),});

  constructor(
               private usuarioService: UsuarioService,
               private route: Router,
  ) { }

  ngOnInit() {
    this.cod = [{}];
  }

  getUserByRut(rut: string){
    this.usuarioService.getCondutorByRut(rut).subscribe(
      response =>{
        if(response){
          this.conductor = response;
          this.rutSearch = rut;
          //console.log("validar rut :",this.validarRut(rut));
          if(this.conductor[0].Conductor !== "RUT INCORRECTO"){
            this.cod = response;
          }else{
            this.rutSearch = null;
            Swal.fire(this.conductor[0].Conductor, 'Ups!', 'error');
          }
        }else{
          console.error('Error eh!');
        }
      });

  }
  updateConductor(nombre: string, usuario: string, pass: string){
    this.usuarioService.updateConductor(this.rutSearch,nombre,usuario,pass).subscribe(response =>{
      this.respuesta =response;
      Swal.fire(this.respuesta[0].Respuesta , this.rutSearch , 'success');
    });
  }
  
  limpiar(){
    this.rutSearch = null;
    this.forma.reset({ monto: 0 });
  }

  validarRut(rut :string){
  //Soon....
  }


}
